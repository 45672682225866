import styles from "./Skeleton.module.scss";

export function Skeleton({
  type,
  height,
}: {
  type: "text" | "rect" | "circle";
  height?: number;
}) {
  return (
    <div
      style={height ? { height: height } : {}}
      className={`${styles.Skeleton} ${styles[type]} ${
        type !== "circle" ? styles.shimmer : ""
      }`}
    ></div>
  );
}
